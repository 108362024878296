const nl = {
    'culture': 'nl-NL',
    'actions': {
        'add': 'Toevoegen',
        'approverecords': 'Goedkeuren',
        'addPeriod': 'Periode toevoegen',
        'back': {
            'imports': 'Terug naar importbestanden'
        },
        'backtoadmin': 'Terug naar beheer',
        'backtooriginaluser': 'Terug naar oorspronkelijke gebruiker',
        'backtomobile': 'Terug naar mobiel',
        'connect': 'Koppelen',
        'copyurl': 'Kopieer link',
        'close': 'Sluiten',
        'delete': 'Verwijderen',
        'desktopview': 'Bureaubladweergave',
        'edit': 'Bewerken',
        'generateinvoice': 'Factuur genereren',
        'impersonate': 'Inzien',
        'login': 'Inloggen',
        'logout': 'Uitloggen',
        'next': 'Volgende',
        'previous': 'Vorige',
        'ok': 'Ok',
        'save': 'Opslaan',
        'search': 'Zoeken',
        'importfile': 'Importeer bestand',
        'register': 'Aanmelden',
        'unregister': 'Afmelden',
        'geticalurl': 'Krijg ical url',
        'submit': 'Versturen',
        'view': 'Bekijken',
        'sendmessage': 'Bericht versturen',
        'export': 'Exporteren',
        'reopen': 'Heropenen',
        'unlock': 'Ontgrendelen',
        'sendinvoice': 'Factuur verzenden',
        'setReport': 'Sjabloon gebruiken',
        'registertime': 'Registreer tijd',
        'mergeinvoice': 'Factuur samenvoegen',
        'printinvoice': 'Factuur afdrukken',
        'makecreditinvoice': 'Creditnota',
        'sendtobasecone': 'Verzenden naar Basecone',
        'previousreports': 'Eerdere rapporten'

    },
    'confirm': {
        'leaveWithoutSave': '<strong>Weet u het zeker?</strong><br />Er zijn niet opgeslagen wijzigingen',
        'delete': '<strong>Weet u het zeker?</strong><br />Record verwijderen',
        'waitinglist': 'Momenteel is deze activiteit vol.<br> Wil je op de wachtlijst komen?',
        'approve': 'wil je de tijd goedkeuren?',
        'closetime': 'Weet u zeker dat u de tijd wilt indienen?',
        'reopentime': 'Weet u zeker dat u de tijd opnieuw wilt openen?',
        'generateinvoice': 'Wilt u een factuur van de huidige maand genereren?'
    },
    'error': {
        'saveChanges': 'Er is een probleem opgetreden tijdens het opslaan van de gegevens',
        'deleteRecordConflict': 'Dit record kan niet worden verwijderd',
        'emailexists': 'Dit e-mailadres is al in gebruik',
        'emailnotfound': 'E-mail niet gevonden',
        'invoiceexists': 'Factuur bestaat al!',
        'reports': 'Deze individuele rapportage is geschreven door een medewerker van Bont indien u vragen heeft over deze rapportage, willen wij aan u vragen om contact op te nemen met uw contactpersoon.'

    },

    'format': {
        'date': 'DD-MM-YYYY',
        'dateTime': 'DD-MM-YYYY HH:mm',
        'time': 'HH:mm',
        'timezone': 'UTC'
    },
    'path': {
        'home': '/',
        'login': '/login',
        'forgotpassword': '/wachtwoordvergeten',
        'activities': '/activiteiten',
        'activitytemplate': '/activiteiten/sjabloon',
        'activitiesbatch': '/activiteiten/batch',
        'activitylist': '/activiteiten/aandachtvereist',
        'editprofile': '/bewerkprofiel',
        'readonlyprofile': '/alleenlezenprofiel',
        'googleformurl': '/bontvoorjou',
        'manage': '/beheer',
        'manage_group': '/beheer/groep',
        'manage_client': '/beheer/jongere',
        'manage_attendant': '/beheer/begeleider',
        'manage_administrator': '/beheer/beheerder',
        'manage_time': '/tijdregistratiebeheer',
        'manage_location': '/beheer/locatie',
        'manage_activitylabel': '/beheer/activiteitenlabel',
        'manage_preparation': '/beheer/voorbereiding',
        'manage_finishing': '/beheer/afronding',
        'manage_club': '/beheer/club',
        'manage_reportdefault': '/beheer/rapportages',
        'manage_timeofdaydetail': '/beheer/dagdelen',
        'texts': '/teksten',
        'texts_faq': '/teksten/faq',
        'texts_manual': '/teksten/handboek',
        'texts_contact': '/teksten/contact',
        'texts_welcome': '/teksten/welkom',
        'texts_googleformurl': '/teksten/bontachterdeschermen',
        'manage_publicprofile': '/profiel',
        'welcomeclient': '/welkom/jongere',
        'welcomeattendant': '/welkom/belegeider',
        'contact': '/contact',
        'faq': '/faq',
        'manualattendant': '/handboek/begeleider',
        'schedule': '/rooster',
        'setpassword': '/login/wijzigwachtwoord',
        'manage_skill': '/beheer/vaardigheden',
        'child_users': '/users/client',
        'timeregistration': '/tijdregistratie/',
        'reports': '/rapportages',
        'reportpresence': '/rapportages/aanwezigheidscontrole',
        'reporttime': '/rapportages/tijdregistratie/',
        'reportattendantcontract': '/rapportages/contracturenperweek',
        'reportattendantleave': '/rapportages/verlofurenperjaar',
        'reportturnover': '/rapportages/omzet',
        'reportnotinvoice': '/rapportages/nietfactureren',
        'reportbudget': '/rapportages/budgetrapporten',
        'admininvoice': '/adminfactuur',
        'invoice': '/factuur',
        'healthcare': '/zorg',
        'clubreports': '/zorg/clubrapportages',
        'medicationrequest': '/zorg/medicatieaanvraag',
        'individualreports': '/zorg/individuelerapporten',
        'medicationreports': '/zorg/medicatierapporten',
        'clientindividualreports': '/individuelerapporten',
        'clientmedication': '/clientmedicatie',
        'birthday': '/verjaardag',
        'attendantcalender': '/weekoverzicht',
        'configurations': '/configuraties',

    },
    'periods': {
        'short': {
            'sunday': 'Zo',
            'monday': 'Ma',
            'tuesday': 'Di',
            'wednesday': 'Wo',
            'thursday': 'Do',
            'friday': 'Vr',
            'saturday': 'Za',
            'january': 'Jan',
            'february': 'Feb',
            'march': 'Maa',
            'april': 'Apr',
            'may': 'Mei',
            'june': 'Jun',
            'july': 'Jul',
            'august': 'Aug',
            'september': 'Sep',
            'october': 'Okt',
            'november': 'Nov',
            'december': 'Dec',
        },
        'long': {
            'january': 'Januari',
            'february': 'Februari',
            'march': 'Maart',
            'april': 'April',
            'may': 'Mei',
            'june': 'Juni',
            'july': 'Juli',
            'august': 'Augustus',
            'september': 'September',
            'october': 'Oktober',
            'november': 'November',
            'december': 'December',
            'sunday': 'Zondag',
            'monday': 'Maandag',
            'tuesday': 'Dinsdag',
            'wednesday': 'Woensdag',
            'thursday': 'Donderdag',
            'friday': 'Vrijdag',
            'saturday': 'Zaterdag',
        },
        'week': 'Week',
        'month': 'Maand',
        'year': 'Jaar',
        'january': 'Januari',
        'february': 'Februari',
        'march': 'Maart',
        'april': 'April',
        'may': 'Mei',
        'june': 'Juni',
        'july': 'Juli',
        'august': 'Augustus',
        'september': 'September',
        'october': 'Oktober',
        'november': 'November',
        'december': 'December',
    },
    'status': {
        'status': 'Status',
        'loading': 'Laden...',
        'norecords': 'Geen gegevens gevonden',
        'loginfailed': 'Gebruikersnaam en/of wachtwoord onbekend',
        'invalidpassword': 'Het wachtwoord is ongeldig',
        'tokenexpired': 'Uw token voor het opnieuw instellen van uw wachtwoord is verlopen',
        'pending': 'In afwachting',
        'approved': 'Goedgekeurd'

    },
    'success': {
        'saveChanges': 'De gegevens zijn succesvol opgeslagen',
        'deleteRecord': 'De gegevens zijn succesvol verwijderd',
        'updatepassword': 'Uw wachtwoord is ingesteld. U kunt nu inloggen',
        'updatestatus': 'Activiteitsstatus is bijgewerkt',
        'resetpassword': 'Reset wachtwoordlink verzonden naar uw e-mailadres!',
        'mailsent': 'De berichten zijn correct verzonden'
    },
    'subtitle': {
        'activitytemplate': 'Een overzicht van alle activiteitssjablonen in het systeem',
        'activitywarnings': 'Een overzicht van activiteiten die aandacht vereisen',
        'attendant': 'Een overzicht van alle begeleiders in het systeem',
        'administrator': 'Een overzicht van alle beheerders in het systeem',
        'birthday': 'Verjaardag',
        'client': 'Een overzicht van alle jongeren in het systeem',
        'faq': 'Een overzicht van veelgestelde vragen',
        'group': 'Een overzicht van alle groepen in het systeem',
        'home': 'Welkom op BONT-rooster',
        'schedule': 'Bekijk alle aankomende activiteiten',
        'skill': 'Een overzicht van alle vaardigheden in het systeem',
        'manual': 'Een overzicht van alle hoofdstukken uit het handboek',
        'publicprofile': 'Bekijk de openbare profielen van andere BONT-jongeren',
        'googleformurl': 'Vul het formulier in om je aan te melden voor BONT',
        'contact': 'Neem contact op met BONT',
        'club': 'Club',
        'timeofdaydetail': 'Dagdelen',
        'attendantcalender': 'Weekoverzicht',
        'configurations': 'Instellingen',
        'location': 'Locatie',
    },
    'worktype': {
        'Description': 'Werksoort',
        'Supervision': 'Begeleiden',
        'Organisation': 'Organiseren',
        'Leave': 'Verlof'
    },
    'timestatus': {
        'New': 'Open',
        'Approved': 'Goedgekeurd',
        'Denied': 'Afgekeurd'
    },
    'enum': {
        'unit': {
            'Hours': 'Uren',
            'Trips': 'Ritten',
            'LateFee': 'Aantal',
        },
        'emailtype': {
            'Reminder': 'Herinnering',
            'Original': 'Origineel',
        },
        'invoicetype':
        {
            'Debit': 'Debet',
            'Credit': 'Credit'
        },
        'typeofmedication':
        {
            'Default': 'Standaard',
            'Homeopathic': 'Homeopatisch',
            'Psychiatric': 'Psychiatrisch',
            'Somatic': 'Somatisch'
        },
        'timeofday': {
            'Morning': 'Morgen',
            'Afternoon': 'Middag',
            'Evening': 'Avond',
			'Night': 'Nacht'
        },
        'administratorstatus': {
            'NotAdministered': 'Niet toegediend',
            'Administered': 'Beheerd door begeleider',
            'Checked': 'Gecontroleerd door begeleider'
        },
        'indicationtype': {
            'WLZ': 'WLZ',
            'Jeugdwet': 'Jeugdwet'
        }
    },
    'title': {
        'activity': 'Activiteit|Activiteiten',
        'activitytemplate': 'Sjablonen',
        'activitiesbatch': 'Batch bewerken',
        'activitylabel': 'Activiteitenlabel',
        'activitylist': 'Lopende activiteiten',
        'activityperiod': 'Activiteitsperiode',
        'activitymessage': 'Activiteit bericht',
        'administrator': 'Beheerders',
        'addnewrecord': 'Nieuw record toevoegen',
        'attendant': 'Begeleiders',
        'attendantcalender': 'Weekoverzicht',
        'activityunsubscribe': 'Activiteit afmelden',
        'birthday': 'Verjaardagen',
        'budgetreports': 'Budgetrapporten',
        'changepassword': 'Wijzig wachtwoord',
        'childusers': 'Kind gebruikers',
        'client': 'Jongeren',
        'club': 'Club',
        'clubreports': 'Clubrapportages',
        'configurations': 'Instellingen',
        'contact': 'Contact',
        'contracthoursperweek': 'Contracturen Per Week',
        'details': 'Details',
        'editprofile': 'Bewerk profiel',
        'faq': 'Veelgestelde vragen',
        'finishing': 'Afronding',
        'general': 'Algemeen',
        'generateinvoice': 'Factuur genereren',
        'googleformurl': 'BONTachterdeschermen',
        'group': 'Groepen',
        'healthcareprofile': 'Zorgprofiel',
        'healthcare': 'Zorg',
        'invoice': 'Factuur',
        'individualreport': 'Individueel rapport',
        'individualreports': 'Individuele rapporten',
        'invoicehistory': 'Factuurgeschiedenis',
        'invoicespecification': 'Factuurspecificatie',
        'home': 'Home',
        'leavehoursperyear': 'Verlofuren per jaar',
        'location': 'Locatie',
        'manage': 'Beheer',
        'manual': 'Handboek',
        'medication': 'Medicatie',
        'medicationrequest': 'Medicatie aanvraag',
        'medicationreports': 'Medicatie rapporten',
        'medicines': 'Medicijnen',
        'navigation': 'Navigatie',
        'notinvoice': 'Niet Factureren',
        'preparation': 'Voorbereiding',
        'portal': 'BONTrooster.nl',
        'publicprofile': 'BONT jongeren',
        'setpassword': 'Stel een wachtwoord in',
        'texts': 'Teksten',
        'updatepassword': 'Wijzig wachtwoord',
        'testenvironment': 'TESTVERSIE',
        'welcome': 'Welkom',
        'schedule': 'Rooster',
        'skill': 'Vaardigheden',
        'timeofdaydetail': 'Dagdelen',
        'activitywarnings': 'Aandacht vereist',
        'icalurl': 'Koppelen aan agenda',
        'forgotpassword': 'Wachtwoord vergeten?',
        'sendmessage': 'Bericht versturen',
        'timeregistration': 'Tijdregistratie',
        'reports': 'Rapportages',
        'reportpresence': 'Aanwezigheidscontrole',
        'sendinvoice': 'Eerder verzonden',
        'turnover': 'Omzet',
    },
    'warnings': {
        'insufficientAttendants': 'Niet genoeg begeleiders',
        'unapprovedAttendants': 'Goed te keuren begeleiders',
        'unapprovedClientsFromWaitingList': 'Jongere van wachtlijst goed te keuren',
        'overLapAttendant': 'Begeleider dubbel ingeroosterd',
        'missingAttendantInfo': 'Ontbrekende begeleidersinfo',
        'insufficientRegistration': 'Onvoldoende aanmeldingen',
        'missingClientInfo': 'Ontbrekende jongereninfo',
        'timealert': 'Let op, je schrijf nachturen. Als je gelogeerd hebt dien je dit in drie verschillende shifts aan te bieden (avond, nacht/slapen en ochtend). Bij de nact/slaapuren zet je het vinkje \'nachturen\' aan.',
        'notaccessable': 'Pagina is niet toegankelijk',
        'individualreport': 'Deze individuele rapportage is geschreven door een medewerker van Bont indien u vragen heeft over deze rapportage, willen wij aan u vragen om contact op te nemen met uw contactpersoon.'

    },
    'active': 'Actief',
    'activitylabel': 'Activiteitenlabel',
    'activityperiodstartdate': 'Activiteitsperiode startdatum',
    'activityperiodenddate': 'Einddatum van de activiteitsperiode',
    'activityperioddescription': 'Omschrijving',
    'activitytemplate': 'Activiteiten sjabloon',
    'activityreminderdays': 'Herinnering voor activiteiten (dagen)',
    'activityreport': 'Activiteiten rapport',
    'addedby': 'Toegevoegd door',
    'address': 'Adres',
    'administrator': 'Beheerder',
    'administratorstatus': 'Beheerdersstatus',
    'administeredattendant': 'Toegediend door',
    'and': 'en',
    'answer': 'Antwoord',
    'approved': 'Goedgekeurd',
    'arrangement': 'Arrangement',
    'attendant': 'Begeleider|Begeleiders',
    'attendantnotes': '* Als je "toon" aanvinkt, dan kunnen andere begeleiders deze gegevens van jou inzien. Ouders en jongeren kunnen deze gegevens niet inzien.',
    'attendantanswer': 'Antwoord voor begeleiders',
    'attendantsinfo': 'Info voor begeleiders',
    'attendanttabbeforetime': 'Activiteitgegevens wijzigbaar voor begeleiders voor start activiteit (minuten)',
    'attendanttabtime': 'Activiteitgegevens wijzigbaar voor begeleiders na einde activiteit (minuten)',
    'hideIndividualReportForAttendantBefore': 'Zorgprofiel in te zien voor begeleiders voor start activiteit (dagen)',
    'hideIndividualReportForAttendantAfter': 'Zorgprofiel in te zien voor begeleiders na einde activiteit (dagen)',
    'availableforattendant': 'Beschikbaar voor begeleider|Beschikbaar voor begeleiders',
    'availableforclient': 'Beschikbaar voor jongere|Beschikbaar voor jongeren',
    'avgregisteractivitypermonth': 'Activiteiten per maand',
    'automatic': 'Automatisch',
    'amount': 'Bedrag',
    'registeredforattendant': 'Aangemelde begeleider|Aangemelde begeleiders',
    'registeredforclient': 'Aangemelde jongere|Aangemelde jongeren',
    'unregisteredforclient': 'Afgemelde jongeren',
    'blocked': 'Geblokkeerd',
    'budget': 'Begroting',
    'city': 'Plaats',
    'chapter': 'Hoofdstuk',
    'childOf': 'Kind van',
    'checkedattendant': 'Gecontroleerd door',
    'client': 'Jongere|Jongeren',
    'clientnotes': '* Als je "toon" aanvinkt, dan kunnen andere ouders deze gegevens van jou inzien',
    'clientanswer': 'Antwoord voor jongeren',
    'clientprofile': 'Profiel van de jongere',
    'clientprofileshort': 'Profiel van de jongere korte beschrijving',
    'closeddate': 'Gesloten datum',
    'club': 'Club',
    'code': 'Code',
    'color': 'Kleur',
    'confirmpassword': 'Bevestig',
    'contact': 'Contact',
    'contactinfo': 'Contactgegevens',
    'contactinfoshort': 'Contactgegevens korte beschrijving',
    'contracthoursperweek': 'Contracturen Per Week',
    'contents': 'Inhoud',
    'country': 'Land',
    'coordinatingattendant': 'Coordinerende begeleider',
    'costs': 'Kosten',
    'customdeadline': 'Deadline uitschrijving',
    'createfromtemplate': 'Maken van sjabloon',
    'creditcompleteamount': 'Volledig crediteren',
    'creditpartially': 'Deels crediteren',
    'currentpassword': 'Huidig wachtwoord',
    'day': 'Dag | Dagen',
    'date': 'Datum',
    'deadlinedays': 'Deadline voor aanmelden (dagen)',
    'deadlineunregisterdays': 'Deadline voor afmelden (dagen)',
    'description': 'Omschrijving',
    'deleted': 'Verwijderd',
    'denialreason': 'Reden afkeuren',
    'descriptionCanNotUnregisterAutomatically': 'De deadline om u af te melden voor deze activiteit is verstreken.<br/>Wilt u zich toch afmelden, vult u dan hieronder de reden in.',
    'dateofbirth': 'Geboortedatum',
    'difference': 'Verschil',
    'distance': 'Afstand',
    'donotinvoice': 'Factureer niet',
    'end': 'Eind',
    'enddate': 'Einddatum',
    'enddateindication':'Einddatum indicatie',
    'endtime': 'Eindtijd',
    'endlocation': 'Eindlocatie',
    'enddateShort': 'Eind',
    'email': 'E-mail',
    'emailtype': 'E-mailtype',
    'emailaddress': 'E-mailadres',
    'expired': 'Niet meer geldig',
    'fallbackemailforattendantemail': 'Fallback e-mail voor coordinerend begeleider',
    'filename': 'Bestandsnaam',
    'firstname': 'Voornaam',
    'firstaidattendant': 'BHV-er',
    'finishing': 'Afronding',
    'financingvia':'Financiering via',
    'forgotPassword': 'Vul hieronder uw e-mailadres in en klik op "versturen" om een nieuw wachtwoord in te stellen.',
    'from': 'Van',
    'formurl': 'Formulier url',
    'generateinvoice': 'Factuur genereren',
    'group': 'Groep | Groepen',
    'highlight': 'Highlight',
    'hideinfo': 'Info Verbergen',
    'healthcarealerts': 'Alerts voor de zorg',
    'healthcarealertsshort': 'Alerts voor de zorg korte beschrijving',
    'indicationtype':'Type indicatie',
    'validfrom': 'Geldig vanaf',
    'validuntil': 'Geldig tot',
    'general': 'Algemeen',
    'googleformurl': 'BONTachterdeschermen',
    'hourlyrate': 'Uurtarief',
    'icaldescription': 'Kopieer de onderstaande link en plak deze in uw agenda-applicatie',
    'isdeleted': 'Is verwijderd?',
    'identificationnumber': 'Identificatie Nummer',
    'individualreports': 'Individuele rapporten',
    'initials': 'Voorletters',
    'invoice': 'Factuur',
    'invoiced': 'Gefactureerd',
    'islimitedadministrator': 'Is beperkt beheerder',
    'isnightactivity': 'Is nachtactiviteit',
    'invoicetype': 'Soort',
    'labelTitle': 'Titel',
    'language': 'Taal',
    'leavehours': 'Aantal verlofuren',
    'leavehoursperweek': 'Verlofuren per jaar',
    'lastname': 'Achternaam',
    'linkhasbeencopied': 'De link is gekopieerd',
    'icalurl': 'iCal-URL',
    'isreadonly': 'Is alleen-lezen',
    'includehomeworktravel': 'Woon-werkverkeer',
    'isindividualreportvisible': 'Individueel rapport zichtbaar?',
    'iscoordinatingattendant': 'Coordinerend begeleider?',
    'isincassette': 'Is cassette',
    'ismanaged': 'In eigen beheer',
    'location': 'Locatie',
    'logo': 'Afbeelding',
    'manager': 'Beheerder',
    'manual': 'Handboek',
    'maxusercount': 'Maximaal aantal jongeren',
    'memo': 'Memo',
    'medicationandprotocols': 'Medicatie & protocollen',
    'medicationandprotocolsshort': 'Medicatie & protocollen korte beschrijving',
    'medicationrequest': 'Medicatie aanvraag',
    'medicationreports': 'Medicatie rapporten',
    'middlename': 'Tussenvoegsel',
    'month': 'Maand|Maanden',
    'message': 'Bericht',
    'max': 'Max',
    'name': 'Naam',
    'nameofclient': 'Naam jongere',
    'nameofmedicine': 'Naam van medicijn',
    'newpassword': 'Nieuw wachtwoord',
    'nextday': 'Volgende dag',
    'no': 'Nee',
    'noofclientsperattendent': 'Aantal jongeren per begeleider',
    'notsignedup': 'Niet aangemeld',
    'notinvoice': 'Niet Factureren',
    'notadministered': 'Niet toegediend',
    'number': 'Nummer',
    'numberoftrips': 'Aantal reizen',
    'night': 'Nacht',
    'off': 'Uit',
    'on': 'Aan',
    'organization': 'Organisatie',
    'original': 'Origineel',
    'onwaitinglist': 'Op de wachtlijst',
    'otherobligations': 'Andere verplichtingen',
    'othernamely': 'Anders, namelijk...',
    'opportunitiestolearn': 'Leerkansen',
    'opportunitiestolearnshort': 'Leerkansen korte beschrijving',
    'outofstation': 'Afwezig?',
    'password': 'Wachtwoord',
    'passwordRequirements': 'Het wachtwoord dient minimaal 6 tekens lang te zijn en minimaal: 1 hoofdletter, 1 kleine letter, 1 getal en een speciaal teken (b.v. , . _ & ? etc) te bevatten',
    'passwordRequirements1': 'Wachtwoord moet minimaal 6 tekens lang zijn',
    'passwordRequirements2': '1 hoofdletter en 1 kleine letter vereist',
    'passwordRequirements3': '1 cijfer en 1 speciaal teken (b.v. , . _ & ? etc) vereist',
    'percentage': 'Percentage',
    'period': 'Periode',
    'phone': 'Telefoon',
    'phonenumbercaretaker': 'Telefoon verzorger',
    'phonenumberclient': 'Telefoon jongere',
    'planned': 'Ingedeeld',
    'plannedAttendants': 'Ingedeelde begeleiders',
    'postaladdress': 'Postadres',
    'postalcode': 'Postcode',
    'preparation': 'Voorbereiding',
    'previousreports': 'Eerdere rapporten',
    'priceday': 'Prijs dag',
    'pricenight': 'Prijs nacht',
    'pricetravel': 'Prijs reizen',
    'priceindividual': 'Prijs individueel',
    'question': 'Vraag',
    'quantity': 'Aantal',
    'reason': 'Reden',
    'readmore': 'Lees verder',
    'recurringactivity': 'Terugkerende activiteit',
    'recurringenddate': 'Einddatum',
    'recurringfrequency': 'Frequentie',
    'repeattype': 'Herhaal elke',
    'remarks': 'Opmerkingen',
    'remarkscontract': 'Opmerkingen contract',
    'reminder': 'Herinnering',
    'registrationdeadline': 'Deadline voor registratie (dagen)',
    'resendother': 'Oorspronkelijke factuur opnieuw verzenden naar ander bedrijf?',
    'result': 'Resultaat',
    'unregistrationdeadline': 'Deadline voor uitschrijving (dagen)',
    'unregisterclose': 'Afgemeld, nadat de afmeldtermijn is verstreken',
    'socialmediainfo': 'Social media',
    'selecttemplate': 'Selecteer sjabloon',
    'sendinvitation': 'Stuur uitnodiging',
    'sendpassword': 'Verstuur e-mail',
    'sendinvoice': 'Factuur verzenden',
    'sender': 'Afzender',
    'sendremarksbyemail': 'Send remarks by email',
    'sent': 'Verzonden',
    'sentbookkeeping': 'Verzonden boekhouding',
    'seqno': '',
    'setpassword': 'Stel een wachtwoord in',
    'settings': 'Instellingen',
    'shortdescription': 'Kort',
    'signedup': 'Aangemeld',
    'sickness': 'Ziekte',
    'submit': 'Indienen',
    'skill': 'Vaardigheden',
    'start': 'Start',
    'startdate': 'Startdatum',
    'starttime': 'Starttijd',
    'startlocation': 'Start locatie',
    'startdateShort': 'Start',
    'standard': 'standaard',
    'state': 'Status',
    'street': 'Straat',
    'streetnumber': 'Huisnummer',
    'supervision100': 'Toezicht 100%',
    'supervision50': 'Toezicht 60%',
    'showaddress': 'Toon adres',
    'showcity': 'Toon plaats',
    'showdateofbirth': 'Toon geboortedatum',
    'showmore': 'Laat meer zien',
    'showname': 'Toon naam',
    'showphonenumberclient': 'Toon telefoonnummer',
    'showphonenumbercaretaker': 'Toon telefoonnummer',
    'showphonenumbers': 'Toon telefoonnummers',
    'showpostalcode': 'Toon postcode',
    'showsocialmediainfo': 'Toon social media',
    'showemailaddress': 'Toon e-mailadres',
    'specification': 'Specificatie',
    'splitUpActivity': 'Activiteit opsplitsen voor begeleiders',
    'statefrom': 'Van status',
    'stateto': 'Naar status',
    'subaccount': 'Sub-accounts',
    'subject': 'Onderwerp',
    'sort': 'Soort',
    'smugmug': 'Smugmug',
    'socialmedia': 'Sociale media',
    'unregisteredhours': 'Notificatie bij afmelden (uren)',
    'timeofday': 'Tijdstip',
    'typeofmedication': 'Soort medicatie',
    'timeregistration': 'Tijdregistratie',
    'timeregistrationreject': 'Tijdregistratie Weigeren e-mailonderwerp',
    'total': 'Totaal',
    'totaldays': 'Totaal dagen',
    'totaldeclarabel': 'Totaal declarabel',
    'type': 'Soort',
    'to': 'Aan',
    'totaldistance': 'Totale afstand',
    'toweek': 'Tot week',
    'toyear': 'Aot jaar',
    'unit': 'Eenheid',
    'unitprice': 'Prijs per stuk',
    'upto': 't/m',
    'usertype': 'Gebruikerstype',
    'vat': 'BTW',
    'view': 'Weergave',
    'visitingaddress': 'Bezoekadres',
    'warning': 'Waarschuwing',
    'website': 'Website',
    'week': 'Week',
    'year': 'Jaar',
    'yes': 'Ja',
};

export default nl;